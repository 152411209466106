import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Actions/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import SettingsTable from 'components/Web_User_Interface/1440p_Series/Alarm/Actions/settingsTable';
import ActionsTable from 'components/Web_User_Interface/1440p_Series/Alarm/Actions/actionsTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Actions",
  "path": "/Web_User_Interface/1440p_Series/Alarm/Actions/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Adjust the cameras behaviour in case of an alarm trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 128 GB) to use the video recording and send-to-FTP function.",
  "image": "./WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Actions' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adjust the cameras behaviour in case of an alarm trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 128 GB) to use the video recording and send-to-FTP function.' image='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Alarm/Aktionen/' locationFR='/fr/Web_User_Interface/1440p_Series/Alarm/Actions/' crumbLabel="Alarm Actions" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-actions",
        "aria-label": "alarm actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Actions`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "what-do-you-want-your-camera-to-do-in-case-of-an-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-do-you-want-your-camera-to-do-in-case-of-an-alarm",
        "aria-label": "what do you want your camera to do in case of an alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What do you want your camera to do in case of an alarm?`}</h2>
    <p>{`The software-based motion detection used by your camera for the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{` does not need any additional hardware to work and can be very effective in controlled indoor environments. The disadvantage is that this detection does not discriminate causes for changes inside the cameras field of view - a cloud moving by in front of the sun, raindrops or vegetation moving in the wind - everything that changes enough pixels will trigger an alert. Please activate the integrated Passive Infrared Sensor and link it with the software based detection to minimize false alerts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1b85721246af4c934af41a659f426be/121b3/1440p_Settings_Alarm_Actions_Settings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACY0lEQVQoz2WRS08TURiG+QtGTRQ0GBYCxQKGQLlr22kZph3mcs6ZoS3YBMSSYOXSEo0KYiDITRa4cGWiCxJBNyYs3OlvcOuPecwMl5qweHNyzpvvyft+py4ajdLSfJf09AZjqycYL76RXj4mWT1Gq3xFWzpEqxyRrB6RrByhBWf1mJHqIf76CfbsBg03rlNf30BTUxN1bW1ttDQ3k8k/pbB8gD+/g3q2gyxvIxcPUM8/oRb28eZ38Rb28Ob3Qk8tfaDw+guj/izRyD06OrqIRjtOga2trWjxIXw3i2vqOIGMBE5hBrF4gCskytKRdgZpGaHnTi0jqx8xMxammSMRH6Gnu68GTKXS5PIFlOcjlI9jmbilVeS7X4iJEr6nEMrDcQWuY2MVK5hz20hrAqU/Ip1M0dvbe1a5pYVUKkU+n0cpFQ45YxnE7Bpq6zdy8gk5X6ICoOPgCsGoPsKDRJySEKxP51BKo6+3pwbUNA3f95FS4rourutg2S6GJbAdJwSFsNA79TOmRXlmgr1VH18O0ReL/V85RS6XQwgRDgTDUgjGfe/s7l68h54U6KOjbG9t8vPzLtIYpLs7Rl0kErlIGACDhOdJLMsim81i2/YpLNifOG2ggtoBcO0Nf78fs6iy3O/qurxDz/NqCaVkfHy8lsw0cLIjjFkmhpQMGwbv367w5+cP9kuKxHCsBtR1nWKxGALOFew0UPBRUrh4pZfIhV2mJ+fYzI6xkrEoTxV5NTfNhN7PYKyzVnlgYIB0Oh1WTyaTl5WIo4nHJAoV7IxPuX+Q8sMkne3tXLl6jZu3btPYeId/lWOvwNCbhEMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1b85721246af4c934af41a659f426be/e4706/1440p_Settings_Alarm_Actions_Settings.avif 230w", "/en/static/b1b85721246af4c934af41a659f426be/d1af7/1440p_Settings_Alarm_Actions_Settings.avif 460w", "/en/static/b1b85721246af4c934af41a659f426be/7f308/1440p_Settings_Alarm_Actions_Settings.avif 920w", "/en/static/b1b85721246af4c934af41a659f426be/07541/1440p_Settings_Alarm_Actions_Settings.avif 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1b85721246af4c934af41a659f426be/a0b58/1440p_Settings_Alarm_Actions_Settings.webp 230w", "/en/static/b1b85721246af4c934af41a659f426be/bc10c/1440p_Settings_Alarm_Actions_Settings.webp 460w", "/en/static/b1b85721246af4c934af41a659f426be/966d8/1440p_Settings_Alarm_Actions_Settings.webp 920w", "/en/static/b1b85721246af4c934af41a659f426be/893d8/1440p_Settings_Alarm_Actions_Settings.webp 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1b85721246af4c934af41a659f426be/81c8e/1440p_Settings_Alarm_Actions_Settings.png 230w", "/en/static/b1b85721246af4c934af41a659f426be/08a84/1440p_Settings_Alarm_Actions_Settings.png 460w", "/en/static/b1b85721246af4c934af41a659f426be/c0255/1440p_Settings_Alarm_Actions_Settings.png 920w", "/en/static/b1b85721246af4c934af41a659f426be/121b3/1440p_Settings_Alarm_Actions_Settings.png 1070w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1b85721246af4c934af41a659f426be/c0255/1440p_Settings_Alarm_Actions_Settings.png",
              "alt": "Web User Interface - 1440p Series - Alarm Actions",
              "title": "Web User Interface - 1440p Series - Alarm Actions",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SettingsTable mdxType="SettingsTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "use-the-internal-sensor-eg-in-9408-wqhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-the-internal-sensor-eg-in-9408-wqhd",
        "aria-label": "use the internal sensor eg in 9408 wqhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the internal Sensor (e.g. IN-9408 WQHD)`}</h3>
    <p>{`Activating the internal or external PIR sensor allows you to limit the motion detection to objects whose temperature differs from the background. In most cases, this will be a person or animal in front of the cameras or the warm engine of a car. Changes in light conditions, fog in front of the camera at night or movements in the wind will `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Passive_Infrared_Detection/"
      }}>{`not be detected by the PIR sensor`}</a>{`.`}</p>
    <p>{`By coupling the PIR sensor with the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`detection areas`}</a>{`, you will only get an alarm event if both detection methods were triggered at the same time. This will greatly reduce the number of false alerts you might have. But be aware that it also might eliminate a few positive alerts.`}</p>
    <h3 {...{
      "id": "use-an-external-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-an-external-sensor",
        "aria-label": "use an external sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use an external Sensor`}</h3>
    <p>{`You can use a motion detector like the `}<a parentName="p" {...{
        "href": "/en/Products/IN-Motion/500/"
      }}>{`IN-MOTION 500`}</a>{` to improve the detection rate of your camera (`}<strong parentName="p">{`Only cameras with an integrated Alarm-Input`}</strong>{`). `}<a parentName="p" {...{
        "href": "/en/Products/IN-Motion/500/Wiring/"
      }}>{`Connect the Sensor`}</a>{` to the alarm input and activate the external input inside the webUI. The camera will trigger an alarm when the contact is bridged and the relay state is set to `}<em parentName="p">{`close on alarm`}</em>{` (n.o. `}<strong parentName="p">{`Normally Open`}</strong>{`). If it is set to `}<em parentName="p">{`open on alarm`}</em>{`, the alarm will be triggered once the circuit is broken by the relay inside the detector (n.c. `}<strong parentName="p">{`Normally Closed`}</strong>{`) or if the wire is disconnected. The state you have to choose depends on your sensor - the wrong choice will give a continuous alert once the detector is connected.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dd64a47116de87e53634635d20aee426/024d6/1440p_Settings_Alarm_Actions_Actions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC60lEQVQ4y2WTS28bVRiG8yOQEERJk2xCHKc4IXFAje2O7cSXGc/Muc7FdpqbqEBBFRRYIEICVEi0YQNIsIAdy4oUIfEj+FMPmuM6De3i3Zxzvue8322mXC5TKq1QXlvDO/oO7/6PNE5+4M7hE3acLp/ryQsdXXLn4Huih79R76e88fprvDk7y8LCAjMFcGWlAN4mP/uD4aO/GX77DHvxDHN+hfnqKeb8T8zF1bX0+RXZN1ccP/6HWj9n6dY8y8srLC+/xUypVKJQAfW7beSgS+R3iIMOOg4wSYoIA0zcJzMxOuoT+3vEfse9a95tUttp0qi32Np6l5nV1VWmUCkVWT7EJinGaEQyJnzwE2L/lCy1ZPlocmcTun2f3d0OnVaHes17AbzpMI5jkiTBGIPWmigMaTZ2CPw+1lp3ppRCKU0Y+IR+n6C3R9PzqNdabG3ecFgARRyRpqkLEHGIsEP0Jz+j9k9JrSJNM2RxF/ro+2fYz35FRLt4jYYDVl92WKQ4Go1J7MShMRY7OkYnufsoy3LSLEcMuqjPf0df/kvQa+PV67SbTbar2/8HSmXIh2N0foC1xklLgVISa4xL29oEGfYRp49RZ0/p7bVp3a2x2/Sobr0MFMK5kNogpSSKIlrtNoPAJ7934j6zU/c2QduMoejTaXm0vDbV6nuTOSzqWACL2uV5fh2klSIOA4TSpPsnZFl63bRiCrQ2GN9je3OdjY1N1sq3Jw6njSlSy7MMmyRoJVD5Ifbrv1Dvf0GSj8myjCRNnwONq+vblQqzbktuOV2n7IA6YTTed7VSMkYmI8ynvyDvfYSUAqmU67KSCiUERmve2dhgfn6epaUlFhcXp8BVt8+RLtxl7qEuXGiFjnrIYoSEQEQBYtAjikN6UhJqzfr6OnNzcw52AzhJezAInBMXPJVUCCkRIkYdPEB8+Iij5Jjzbp+HoaBSqTiHrwALhWHoNqEAFV2+KQcsUv/ggoPkkC/9gI8j+QrwPxKbM4wWH8LuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd64a47116de87e53634635d20aee426/e4706/1440p_Settings_Alarm_Actions_Actions.avif 230w", "/en/static/dd64a47116de87e53634635d20aee426/d1af7/1440p_Settings_Alarm_Actions_Actions.avif 460w", "/en/static/dd64a47116de87e53634635d20aee426/7f308/1440p_Settings_Alarm_Actions_Actions.avif 920w", "/en/static/dd64a47116de87e53634635d20aee426/7733b/1440p_Settings_Alarm_Actions_Actions.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dd64a47116de87e53634635d20aee426/a0b58/1440p_Settings_Alarm_Actions_Actions.webp 230w", "/en/static/dd64a47116de87e53634635d20aee426/bc10c/1440p_Settings_Alarm_Actions_Actions.webp 460w", "/en/static/dd64a47116de87e53634635d20aee426/966d8/1440p_Settings_Alarm_Actions_Actions.webp 920w", "/en/static/dd64a47116de87e53634635d20aee426/85db8/1440p_Settings_Alarm_Actions_Actions.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd64a47116de87e53634635d20aee426/81c8e/1440p_Settings_Alarm_Actions_Actions.png 230w", "/en/static/dd64a47116de87e53634635d20aee426/08a84/1440p_Settings_Alarm_Actions_Actions.png 460w", "/en/static/dd64a47116de87e53634635d20aee426/c0255/1440p_Settings_Alarm_Actions_Actions.png 920w", "/en/static/dd64a47116de87e53634635d20aee426/024d6/1440p_Settings_Alarm_Actions_Actions.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dd64a47116de87e53634635d20aee426/c0255/1440p_Settings_Alarm_Actions_Actions.png",
              "alt": "Web User Interface - 1440p Series - Alarm Actions",
              "title": "Web User Interface - 1440p Series - Alarm Actions",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ActionsTable mdxType="ActionsTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      